import React, {useContext} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {
  Grid,
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
 } from '@material-ui/core'
import {Redirect} from 'react-router-dom'
import $tr from '@card-statements/common/transactionReviews'
import {formatTimestamp} from '../utils'
import {Subtitle} from '../components/Typography'
import {GridList} from '../components/GridList'
import Toast from '../components/Toast'
import {LoadingContext} from '../context/LoadingContext'
import {PageSwitcherHeader} from '../components/PageSwitcherHeader'
import MultiSelect from '../components/MultiSelect'
import {rejectStatement, markExportableTransaction, downloadTransactionsZip} from '../communication'
import {PropTypes} from 'prop-types'

const useStyles = makeStyles({
  mainGrid: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'auto auto 1fr',
  },
  link: {
    textDecoration: 'none',
  },
  span: {
    fontWeight: 'bold',
  },
  verifiedTransactionPairing: {
    '&&:hover': {
      background: 'rgba(152, 191, 100, 1)',
    },
    'backgroundColor': 'rgba(152, 191, 100, 0.95)',
  },
  unverifiedTransactionPairing: {
    backgroundColor: 'none',
  },
})

const renderSeparately = ['centerSplit', 'transactionUuid', 'files']

const renderJsonData = (data) => {
  const toBeRendered = Object.fromEntries(
    Object.entries(data).filter(([k,_]) => !renderSeparately.includes(k)))
  return JSON.stringify(toBeRendered).replaceAll(',', ', ')
}

const copyTextToClipboard = async (text) => {
  // https://blog.logrocket.com/implementing-copy-clipboard-react-clipboard-api/
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text)
  } else {
    return document.execCommand('copy', true, text)
  }
}

const ManualPairingPage = (props) => {
  const {data, forceFetch, setWithUserChooser} = props
  const {pendingManualPairings, isFinanceMember, jiraId} = data
  if (!isFinanceMember) return <Redirect to="/" />
  const {startLoading, stopLoading} = useContext(LoadingContext)
  const classes = useStyles()

  const entities = [...new Set(pendingManualPairings.map((tr) => tr[$tr.entity]))]
  const [selectedEntities, setSelectedEntities] = React.useState([])

  const filterPairing = (pairing) => {
    if (selectedEntities.length === 0) return pairing
    return pairing.filter((tr) => selectedEntities.includes(tr[$tr.entity]))
  }
  const  filteredPairings = filterPairing(pendingManualPairings)

  const [notification, setNotification] = React.useState({severity: undefined, text: undefined})
  const [dialogOpenForPairing, setDialogOpenForPairing] =
    React.useState(undefined) // pairing ids object if open
  const [reason, setReason] = React.useState(undefined)
  const clearNotification = () => setNotification({severity: undefined, text: undefined})

  const [verifiedTransactions, setVerifiedTransactions] = React.useState(filteredPairings
    .map((tr) => tr[$tr.exportable]))

  const handleSelect = (isChecked) => {
    setVerifiedTransactions(Array(filteredPairings.length).fill(isChecked))

    filteredPairings.map((row, i) => {
      markExportableTransaction(row, isChecked, jiraId)
    })
  }
  const handleSelectAll = () => {
    handleSelect(true)
  }
  const handleSelectNone = () => {
    handleSelect(false)
  }

  React.useEffect(() => {
    setVerifiedTransactions(filteredPairings
      .map((tr) => tr[$tr.exportable]))
  }, [selectedEntities])

  const renderValue = (x) => (<span className={classes.span}>{x}</span>)

  const copyToClipboard = (data) => {
    copyTextToClipboard(JSON.stringify(data))
    .then(() => setNotification({severity: 'info', text: 'JSON was copied to clipboard'}))
    .catch(() => setNotification({severity: 'warning', text: 'Copy to clipboard failed'}))
  }

  const handleDialogClose = () => {
    setDialogOpenForPairing(undefined)
    setReason(undefined)
  }

  const handleReject = async () => {
    const {id, transactionUuid} = dialogOpenForPairing
    setDialogOpenForPairing(undefined)
    // handle rejection with reason
    try {
      startLoading()
      const {success, error, deletedFiles} = await rejectStatement(id, transactionUuid, reason)
      if (success) {
        const failed = deletedFiles
          .filter(({deleted}) => !deleted)
          .map(({name}) => name)
        if (failed.length > 0) {
          setNotification({severity: 'warning', text: `Not all files from Drive were deleted: ${
            failed.map(({name}) => name).join(', ')}`})
        } else {
          setNotification({severity: 'info', text: 'Pairing was rejected.'})
        }
        await forceFetch()
        setVerifiedTransactions(filteredPairings
          .splice(filteredPairings.findIndex((tr) => tr[$tr.id] === id), 1)
          .map((tr) => tr[$tr.exportable]))
      } else {
        setNotification({severity: 'error', text: error})
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    } finally {
      stopLoading()
    }
  }

  const downloadTransactions = async (url, filename) => {
    setDialogOpenForPairing(undefined)
    try {
      startLoading()
      const {success, error} = await downloadTransactionsZip(url, filename)
      if (!success) {
        setNotification({severity: 'error', text: `Download failed, error: ${error}`})
        return
      }
      setNotification({severity: 'info', text: 'Transactions successfully downloaded.'})
      await forceFetch()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    } finally {
      stopLoading()
    }
  }

  const handleDownload = (transactions_type) => {
    const filename = `cards_${transactions_type}_export_${new Date().toISOString().slice(0, 10)}.zip`
    downloadTransactions(`/api/export-${transactions_type}-transactions-xlsx`, filename)
  }

  setWithUserChooser(false)

  return (<>
    <Box className={classes.mainGrid}>
      <PageSwitcherHeader exclude="/finance-dashboard">
        <Subtitle>Pairing dashboard</Subtitle>
      </PageSwitcherHeader>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <MultiSelect
          label="Filter by entity"
          options={entities}
          setSelected={setSelectedEntities}
          selected={selectedEntities}
        />
        <Button onClick={() => handleDownload('processed')} variant="contained" color="primary" style={{marginLeft: 'auto', marginRight: '5px'}}>
          Export processed transactions
        </Button>
        <Button onClick={() => handleDownload('unprocessed')} variant="contained" color="primary">
          Export unprocessed transactions
        </Button>
      </div>
      <div style={{justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px'}}>
        <Button onClick={handleSelectAll} variant="contained" color="primary" style={{marginRight: '5px'}}>
          Select All
        </Button>
        <Button onClick={handleSelectNone} variant="contained" color="primary">
          Select None
        </Button>
      </div>
      <GridList>
        {filteredPairings.map((row, i) => {
          /* Layout:
                -AB-------------------- -C---------
                | -A----------------- | |         |
                | | separate values | | |         |
                | ------------------- | |  button |
                | -B----------------- | |         |
                | | json            | | |         |
                | ------------------- | |         |
                ----------------------- -----------
          */
          return (
            <Grid container key={row[$tr.id]} className={verifiedTransactions[i] ?
              classes.verifiedTransactionPairing : classes.unverifiedTransactionPairing}>
              <Grid item key={`AB-${row[$tr.id]}`} xs={11}>
                <Grid container spacing={1}>
                <Grid item container key={`A2-${row[$tr.id]}`} xs={12}>
                    <Grid key={'title'} item xs={12} md={3} lg={3}>
                      <Checkbox style={{padding: 0}}
                        color="primary"
                        checked={row[$tr.exportable]}
                        onChange={(e) => {
                          markExportableTransaction(row, e.target.checked, jiraId)
                          setVerifiedTransactions(verifiedTransactions
                            .map((v, j) => j === i ? e.target.checked : v))
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item key={`A-${row[$tr.id]}`} xs={12}>
                    <Grid container spacing={1}>
                      <Grid key={1} item xs={12} md={1} lg={1}>
                        ID: {renderValue(row[$tr.id])}
                      </Grid>
                      <Grid key={2} item xs={12} md={3} lg={2}>
                        Created At: {renderValue(formatTimestamp(row[$tr.createdAt]))}
                      </Grid>
                      <Grid key={3} item xs={12} md={5} lg={4}>
                        Transaction: {renderValue(row[$tr.transaction_uuid])}
                      </Grid>
                      <Grid key={4} item xs={6} md={3} lg={2}>
                        Files: {row[$tr.files] && renderValue(row[$tr.files].map(({id}, i) => {
                          return (<React.Fragment key={i}>
                            {i !== 0 && <span>, </span>}
                            <a
                              href={`https://drive.google.com/file/d/${id}/view?usp=sharing`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              #{i + 1}
                            </a>
                          </React.Fragment>)
                        }))}
                      </Grid>
                      <Grid key={6} item xs={12} md={5} lg={4}>
                        Entity: {renderValue(row[$tr.entity])}
                      </Grid>
                    </Grid>
                  </Grid>
                  {row[$tr.data]?.centerSplit &&
                  <Grid item container key={`A3-${row[$tr.id]}`} xs={12}>
                    <Grid key={'title'} item xs={12} md={3} lg={3}>
                      Centers (CC/SubCC/Location/CA/CT/JiraID/Reporting project):
                    </Grid>
                    {Object.keys(row[$tr.data].centerSplit).map((k) => (
                      <Grid key={k} item xs={12} md={6} lg={10}>
                        <span className={classes.span}>
                          {k}: {row[$tr.data].centerSplit[k]}
                          &nbsp;{row[$tr.data].currency || ''}
                        </span>
                      </Grid>
                    ))}
                  </Grid>
                  }
                  <Grid container wrap="nowrap">
                    <Grid item key={`B-${row[$tr.id]}`} xs zeroMinWidth onClick={() => copyToClipboard(row[$tr.data])}>
                      <span style={{overflowWrap: 'break-word', whiteSpace: 'normal'}}>
                        JSON data: {renderJsonData(row[$tr.data])}
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item key={`C-${row[$tr.id]}`} xs="auto" justifyContent="center" alignItems="center">
                <Button variant="contained" onClick={() => setDialogOpenForPairing({
                    id: row[$tr.id],
                    transactionUuid: row[$tr.transaction_uuid],
                  })}
                >
                  Reject
                </Button>
              </Grid>
            </Grid>
          )
        })}
      </GridList>
    </Box>
    <Toast
      message={notification.text}
      onClose={clearNotification}
      severity={notification.severity}
    />
    <Dialog open={!!dialogOpenForPairing} onClose={handleDialogClose}>
      <DialogTitle>Rejection</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Write a reason why do you reject the pairing with id: {dialogOpenForPairing?.id}:
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="reason"
          type="email"
          fullWidth
          variant="standard"
          onChange={(event) => setReason(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>Cancel</Button>
        <Button
          onClick={handleReject}
          disabled={reason == null}
        >
          Reject pairing
        </Button>
      </DialogActions>
    </Dialog>
  </>)
}

ManualPairingPage.propTypes = {
  data: PropTypes.object,
  forceFetch: PropTypes.func,
  setWithUserChooser: PropTypes.func,
}

export {ManualPairingPage}
